import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "searching-for-single-and-repeat-tasking-requests"
    }}>{`Searching for Single and Repeat Tasking Requests`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Existing Single and Repeat Tasking Requests can be found using our advanced search endpoints.`}</p>
    <p>{`There are two distinct offerings for each type:`}</p>
    <h3 {...{
      "id": "single-tasking-requests"
    }}><a parentName="h3" {...{
        "href": "#tasking-requests"
      }}>{`Single Tasking Requests`}</a></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`POST https://api.capellaspace.com/tasks/search`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "repeat-tasking-requests"
    }}><a parentName="h3" {...{
        "href": "#repeat-requests"
      }}>{`Repeat Tasking Requests`}</a></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`POST https://api.capellaspace.com/repeat-requests/search`}</inlineCode></li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "request-and-response"
    }}>{`Request and Response`}</h2>
    <h3 {...{
      "id": "request-body"
    }}>{`Request Body`}</h3>
    <p>{`Both endpoints support the following request body structure:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sort`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key scoped inside the task or repeat request properties to sort on. For example: `}<inlineCode parentName="td">{`windowOpen`}</inlineCode>{` or `}<inlineCode parentName="td">{`collectConstraints.imageLength`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`submissionTime`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction to sort the specified property. Must be one of `}<inlineCode parentName="td">{`asc`}</inlineCode>{` or `}<inlineCode parentName="td">{`desc`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`desc`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The page of results to obtain. Search responses will indicate `}<inlineCode parentName="td">{`totalPages`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`limit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of records to return in each search request (e.g. page size).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`250`}</inlineCode>{` (Maximum: `}<inlineCode parentName="td">{`500`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`query`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filters to apply in an `}<inlineCode parentName="td">{`AND`}</inlineCode>{` fashion. See `}<a parentName="td" {...{
              "href": "#the-search-query"
            }}>{`documentation below`}</a>{` for more information.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{}`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "response"
    }}>{`Response`}</h3>
    <p>{`Response on success will have a status of `}<inlineCode parentName="p">{`200`}</inlineCode>{` and results are always paginated (see default values above). `}</p>
    <pre><code parentName="pre" {...{}}>{`{
  results: [
    // Array of Repeat Requests or Tasking Requests. See API reference for schemas
  ],
  "currentPage": 1,
  "totalPages": 20
}
`}</code></pre>
    <h2 {...{
      "id": "permissions"
    }}>{`Permissions`}</h2>
    <p>{`Search results are automatically restricted to tasks viewable by the requesting user. `}</p>
    <p>{`In most cases you can only obtain tasking requests associated with your `}<inlineCode parentName="p">{`userId`}</inlineCode>{`. Certain roles such as `}<inlineCode parentName="p">{`organization-manager`}</inlineCode>{` can view all tasks within an organization.`}</p>
    <h2 {...{
      "id": "the-search-query"
    }}>{`The Search Query`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`query`}</inlineCode>{` parameter is an object containing keys for each property to filter against. All filters are applied in an `}<inlineCode parentName="p">{`AND`}</inlineCode>{` operation.`}</p>
    <p>{`There are several definitions of operators you can provide as a filter value. The type of each field dictates which one is supported.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#standard-search-operators"
        }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#text-search-operators"
        }}><inlineCode parentName="a">{`TextSearchFilterProperty`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#date-time-search-operators"
        }}><inlineCode parentName="a">{`DateTimeSearchFilterProperty`}</inlineCode></a></li>
    </ul>
    <p>{`Each endpoint declares fields and supported operators. `}<inlineCode parentName="p">{`SearchFilterProperty`}</inlineCode>{` is the default unless otherwise specified.`}</p>
    <h1 {...{
      "id": "tasking-requests"
    }}>{`Tasking Requests`}</h1>
    <p>{`To search single tasking requests you will use the `}<inlineCode parentName="p">{`POST /tasks/search`}</inlineCode>{` endpoint.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/tasks/search
`}</code></pre>
    <p>{`See `}<a parentName="p" {...{
        "href": "#request-and-response"
      }}>{`Request and Response`}</a>{`.`}</p>
    <p>{`Recognized Tasking Request `}<inlineCode parentName="p">{`query`}</inlineCode>{` fields:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`taskingrequestName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#text-search-operators"
            }}><inlineCode parentName="a">{`TextSearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`taskName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#text-search-operators"
            }}><inlineCode parentName="a">{`TextSearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alias to `}<inlineCode parentName="td">{`taskingrequestName`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`taskingrequestIds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`taskIds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alias to `}<inlineCode parentName="td">{`taskingrequestIds`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowOpen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#date-time-search-operators"
            }}><inlineCode parentName="a">{`DateTimeFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowClose`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#date-time-search-operators"
            }}><inlineCode parentName="a">{`DateTimeFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusCode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusMessage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alias to `}<inlineCode parentName="td">{`collectConstraints.collectMode`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`submissionTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#date-time-search-operators"
            }}><inlineCode parentName="a">{`DateTimeFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`repeatrequestId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`archiveHoldback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resellerId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`includeRepeatingTasks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Are tasks are spawned from a Repeat Request. Defaults to `}<inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`All other attributes not explicitly defined can be searched using the standard `}<inlineCode parentName="p">{`SearchFilterProperty`}</inlineCode>{` operators, `}<em parentName="p">{`dot scoped`}</em>{` under properties.`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`"query": {
  "collectConstraints.offNadirMin": {
    "gte": 15.0
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`"query": {
  "collectConstraints.offNadirMax": {
    "lte": 48.0
  }
}
`}</code></pre>
    <h1 {...{
      "id": "repeat-requests"
    }}>{`Repeat Requests`}</h1>
    <p>{`To search repeat tasking requests you will use the `}<inlineCode parentName="p">{`POST /repeat-requests/search`}</inlineCode>{` endpoint.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/repeat-requests/search
`}</code></pre>
    <p>{`See `}<a parentName="p" {...{
        "href": "#request-and-response"
      }}>{`Request and Response`}</a>{`.`}</p>
    <p>{`Identical to single task search with exception to supported `}<inlineCode parentName="p">{`query`}</inlineCode>{` fields:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`repeatrequestId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`repeatrequestName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#text-search-operators"
            }}><inlineCode parentName="a">{`TextSearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`repeatrequestDescription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#text-search-operators"
            }}><inlineCode parentName="a">{`TextSearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusCode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusMessage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lastStatusTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alias to `}<inlineCode parentName="td">{`collectConstraints.collectMode`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`submissionTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#date-time-search-operators"
            }}><inlineCode parentName="a">{`DateTimeFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`archiveHoldback`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resellerId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#standard-search-operators"
            }}><inlineCode parentName="a">{`SearchFilterProperty`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`All other properties not explicitly defined can be searched using the standard `}<inlineCode parentName="p">{`SearchFilterProperty`}</inlineCode>{` options, dot scoped under properties. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`"query": {
  "collectConstraints.offNadirMin": {
    "gte": 15.0
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`"query": {
  "collectConstraints.offNadirMax": {
    "lte": 48.0
  }
}
`}</code></pre>
    <h2 {...{
      "id": "operators"
    }}>{`Operators`}</h2>
    <h3 {...{
      "id": "standard-search-operators"
    }}>{`Standard Search Operators`}</h3>
    <p>{`All fields unless superseded by a more specific set of operators support:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`direct comparison`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`array of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`return records matching any value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`object with one of key pair: `}<inlineCode parentName="td">{`eq`}</inlineCode>{`, `}<inlineCode parentName="td">{`lt`}</inlineCode>{`, `}<inlineCode parentName="td">{`lte`}</inlineCode>{`, `}<inlineCode parentName="td">{`gt`}</inlineCode>{`, `}<inlineCode parentName="td">{`gte`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`comparison`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "examples"
    }}>{`Examples`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
  "query": {
    "collectionTier": "background",
    "lastStatusCode": [
      'accepted',
      'submitted
    ]
  }
}
`}</code></pre>
    <h3 {...{
      "id": "text-search-operators"
    }}>{`Text Search Operators`}</h3>
    <p>{`Certain fields are enabled for specific text search capability.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`object value with key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`startsWith`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prefix of field's value must match provided`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`eq`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The entire value must match provided`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contains`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided string must match anywhere inside the search field (regardless of word boundaries`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Values can also be provided as a string which is shorthand for `}<inlineCode parentName="p">{`contains`}</inlineCode>{` search.`}</p>
    <p>{`All search values are `}<strong parentName="p">{`case insensitive.`}</strong></p>
    <h4 {...{
      "id": "examples-1"
    }}>{`Examples`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
  "query": {
    "taskingrequestName": {
      "startsWith": "San Fran"
    },
    "taskingrequestName": {
      "eq": "san francisco"
    },
    "taskingrequestName": "san"
  }
}
`}</code></pre>
    <h3 {...{
      "id": "date-time-search-operators"
    }}>{`Date Time Search Operators`}</h3>
    <p>{`Fields representing dates with times can be search with specific operators.`}</p>
    <p>{`Value should be a string which can represent either a date-time or an interval, open or closed. Date and time expressions adhere to `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc3339"
      }}>{`RFC 3339`}</a>{`. Open intervals are expressed using double-dots.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`input value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Specific DateTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2018-02-12T23:20:50Z`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed Interval`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2018-02-12T00:00:00Z/2018-03-18T12:31:12Z`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Ended Interval`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2018-02-12T00:00:00Z/..`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Beginning Interval`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`../2018-03-18T12:31:12Z`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "examples-2"
    }}>{`Examples`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
  "query": {
    "windowOpen": "2018-02-12T00:00:00Z/.."
  }
}
`}</code></pre>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on constellation tasking requests, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      